import styled from 'styled-components'

const sizes = {
    biggest: 2,
    bigger: 1.6,
    big: 1.25,
    normal: 1.0,
    small: 0.8,
    smaller: 0.5,
    smallest: 0.25,
}

const getColor = (props) => props.col ? props.col :
                            props.primary ? props.theme.primary :
                            props.secondary ? props.theme.secondary :
                            props.background ? props.theme.background :
                            props.highlight ? props.theme.highlight :
                            props.alternative ? props.theme.alternative :
                            props.theme.body;

const getSize = (props) => props.sz ? parseFloat(props.sz) :
                        props.biggest ? sizes.biggest :
                        props.bigger ? sizes.bigger :
                        props.big ? sizes.big :
                        props.small ? sizes.small :
                        props.smaller ? sizes.smaller :
                        props.smallest ? sizes.smallest :
                        sizes.normal


const Text = styled.span`
    margin: 0;
    padding: 0;
    display: ${props => props.block ? "block" : "inline"};
    font-size: ${props => getSize(props) + "rem"};
    line-height: ${props => props.lh ? (parseFloat(props.lh)*getSize(props)) + "rem" : (1.2*getSize(props)) + "rem"};
    font-weight: ${props => props.bold ? "bold" : props.wt ? props.wt : "normal"};
    color: ${props => getColor(props)};
    text-transform: ${props => props.upper ? "uppercase" : props.lower ? "lowercase" : ""};
    text-align: left;
    border: ${props => props.b};
    border-top: ${props => props.bt};
    border-bottom: ${props => props.bb};
    border-left: ${props => props.bl};
    border-right: ${props => props.br};
    width: ${props => props.w ? props.w : "max-content"};
    max-width: ${props => props.mw ? props.mw : ""};
`

export default Text
