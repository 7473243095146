import React from 'react'

import View from '../components/View'
import Box from '../components/Box'
import Text from '../components/Text'

//import workouts from '../content/workouts'

class Timer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {startTime: -1}
    }

    render() {
        // eslint-disable-next-line
        let {movement, sets, reps, tempo} = this.props
        // eslint-disable-next-line
        let {startTime} = this.state;

        return <React.Fragment>
            <View {...this.props}>
                <Box weight={1} col alignself="stretch" mw="450px">
                    <Text>{movement}</Text>
                </Box>
            </View>
        </React.Fragment>
    }
}

export default Timer
