import React from 'react'
import styled from 'styled-components'

import Popout from './Popout'
import Box from './Box'

const Escape = styled(Box)`
    z-index: 90;
    top: 0;
    left: 0;
`

class Switcher extends React.Component {
    constructor(props) {
        super(props)
        this.state = {showMenu: false}
    }

    onShowMenu = (ev) => {
        console.log("Showing the menu")
        this.setState({
            showMenu: true
        })
    }

    onHideMenu = (ev) => {
        console.log("Hiding the menu")
        this.setState({
            showMenu: false
        })
    }

    onSetChild = (child) => {
        let {onChange} = this.props
        onChange(child)
        this.onHideMenu()
    }

    render() {
        let {children} = this.props
        let {showMenu} = this.state
        return <Box rel {...this.props} bb="solid 1px tomato" onClick={!showMenu ? this.onShowMenu : () => {}} pl="1rem" pr="1rem">
            {showMenu && <Escape w="100vw" h="100vh" onClick={this.onHideMenu} fix />}
            {React.Children.toArray(children).map((child, index) => {
                if (child.props.show)
                {
                    return child;
                }
                return null;
            })}
            {showMenu && <Popout>
                    <Box col w="120%" pt="0.5rem" pb="0.5rem">
                        {React.Children.toArray(children).map((child, index) => {
                                return <Box onClick={(ev) => this.onSetChild(index)} key={index} bb={child.props.show ? "solid 1px tomato" : ""} pt={index ? "1rem" : "0"}>{child}</Box>;
                            }
                        )}
                    </Box>
                </Popout>
            }
        </Box>
    }
}

export default Switcher
