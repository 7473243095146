import React from 'react'
import {navigate} from '@reach/router'

import View from '../components/View'
import Box from '../components/Box'
import Text from '../components/Text'
import Switcher from '../components/Switcher'
import Button from '../components/Button'

import workouts from '../content/workouts'
import Exercise from './exercise'

const slug = (path) => path.toLowerCase().replace(/ /gi, "-")

class Workout extends React.Component {
    constructor(props) {
        super(props)
        this.state = {workout: workouts.workouts.length -1, day: 0}
    }

    onChangeWorkout = (workout) => {
        this.setState({
            workout: workout
        })
    }

    onChangeDay = (day) => {
        this.setState({
            day: day
        })
    }

    render() {
        let {workout, day} = this.state
        let {sessions} = workouts.workouts[workout]
        let {groups} = sessions[day]
        console.log(this.props.location)
        let pathPrefix = slug(`/workout/`)
        return <React.Fragment>
            <View {...this.props} path="/workout" nobutton>
                <Box rowr>
                    <Switcher onChange={this.onChangeDay} ml="1rem">
                        {sessions.map(({name}, index) => <Text big key={name} upper show={index === day}>{name}</Text>)}
                    </Switcher>
                    <Switcher onChange={this.onChangeWorkout}>
                        {workouts.workouts.map(({phase}, index) => <Text big key={phase} upper show={index === workout}>{phase}</Text>)}
                    </Switcher>
                    <Box weight="1">
                        {window.navigator.standalone && <Button onClick={() => window.history.back()} small left>back</Button>}
                    </Box>
                </Box>
                <Box col weight={1} justify="center">
                    {groups.map(({exercises}, index) => {
                        return <Box mt="2rem" align="center">
                            <Button onClick={(ev) => navigate(`${pathPrefix}${index+1}`)}>
                                <Box col align="flex-start">
                                    {exercises.map(({movement}, index) => {
                                        return <Text upper w="inherit">{index === exercises.length-1 ? movement: movement + " +" }</Text>
                                    })}
                                </Box>
                            </Button>
                        </Box>
                    })}
                </Box>
            </View>
            {groups.map(({exercises}, index) => {
                return <Exercise path={`${pathPrefix}${index+1}`} group={index} workout={workout} day={day} exercises={exercises}/>
            })}
        </React.Fragment>
    }
}

export default Workout
