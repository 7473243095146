import styled from 'styled-components'

const Circle = styled.div`
    position: absolute;
    z-index: -1;
    width: ${props => props.size || "150vh"};
    height: ${props => props.size || "150vh"};
    top: ${props => props.y ? props.y : 0};
    left: ${props => props.x ? props.x : 0};
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background: ${props => props.bg || props.theme.secondary}
    background-image: ${props => props.img ? "url(" + props.img + ")" : ""};
    transition: 1s ease;
`

export default Circle
