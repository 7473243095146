import React from 'react'
import {navigate} from '@reach/router'

import Box from '../components/Box'
import Text from '../components/Text'
import Circle from '../components/Circle'
import Button from '../components/Button'
import View from '../components/View'

import pattern from '../assets/diagonal-lines.svg'

export default class Home extends React.Component {

    render() {
        return <View {...this.props} path="" nobutton>
            <Circle img={pattern} bg="none" />
                <Box col>
                    <Box>
                        <Text sz={8} wt={800} lh={0.8}>think</Text>
                        <Text sz={8} wt={800} lh={0.8} col="tomato">.</Text>
                    </Box>
                    <Box>
                        <Text sz={8} lh={0.6} primary>gym</Text>
                    </Box>
                </Box>
                <Box weight={1} justify="center" items="center">
                    <Button big solid hvcol="#444444" prhighlight onClick={(ev) => navigate("/login")}>Get Started</Button>
                </Box>
        </View>
    }
}
