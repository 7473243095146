import styled from 'styled-components'

const sizes = {
    biggest: 2,
    bigger: 1.6,
    big: 1.25,
    normal: 1.0,
    small: 0.8,
    smaller: 0.5,
    smallest: 0.25,
}

const getColor = (props) => props.col ? props.col :
                            props.primary ? props.theme.primary :
                            props.secondary ? props.theme.secondary :
                            props.background ? props.theme.background :
                            props.highlight ? props.theme.highlight :
                            props.alternative ? props.theme.alternative :
                            props.theme.body;

const getSize = (props) => props.sz ? parseFloat(props.sz) :
                        props.biggest ? sizes.biggest :
                        props.bigger ? sizes.bigger :
                        props.big ? sizes.big :
                        props.small ? sizes.small :
                        props.smaller ? sizes.smaller :
                        props.smallest ? sizes.smallest :
                        sizes.normal

const Entry = styled.input`
    font-size: ${props => getSize(props)*16 + "px"};
    color : ${props => getColor(props)};
    border : ${props => "1px solid " + getColor(props)};
    padding: ${props => props.small ? "0.25rem 0.5rem" :
                        props.big ? "1.0rem 2.0rem" :
                        "0.5rem 1.0rem"};
    border: ${props => "1px solid " + getColor(props)};
    border-radius: ${props => props.small ? "0.5rem" :
                              props.big ? "2.0rem" :
                              "1.0rem" };
    text-transform: ${props => props.upper ? "uppercase" : props.lower ? "lowercase" : ""};
`

export default Entry
