import styled from 'styled-components'

const flexDirection = ({row, col, rowr, colr}) => (row) ? "row" :
    (col) ? "column" :
    (rowr) ? "row-reverse" :
    (colr) ? "column-reverse" : "row";

const getBackground = (props) => props.bg ? props.bg :
                                props.bgprimary ? props.theme.primary :
                                props.bgsecondary ? props.theme.secondary :
                                props.bgbackground ? props.theme.background :
                                props.bghighlight ? props.theme.highlight :
                                props.bgalternative ? props.theme.alternative :
                                props.bgbody ? props.theme.body : "";

const Box = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: ${props => flexDirection(props)};
    flex: ${props => props.weight};
    justify-content: ${props => props.justify};
    align-items: ${props => props.items};
    align-self: ${props => props.alignself};
    width: ${props => props.w};
    min-width: ${props => props.lw};
    max-width: ${props => props.mw};
    height: ${props => props.h};
    min-height: ${props => props.lh};
    max-height: ${props => props.mh};
    margin: ${props => props.m};
    margin-left: ${props => props.ml};
    margin-right: ${props => props.mr};
    margin-top: ${props => props.mt};
    margin-bottom: ${props => props.mb};
    padding: ${props => props.p};
    padding-left: ${props => props.pl};
    padding-right: ${props => props.pr};
    padding-top: ${props => props.pt};
    padding-bottom: ${props => props.pb};
    border: ${props => props.b};
    border-top: ${props => props.bt};
    border-bottom: ${props => props.bb};
    border-left: ${props => props.bl};
    border-right: ${props => props.br};
    background: ${props => getBackground(props)};
    position: ${props => props.abs ? "absolute" :
                         props.rel ? "relative" :
                         props.fix ? "fixed" : ""};
    box-shadow: ${props => props.float ? "0px 2px 5px 0px #0003" : ""};
    border-radius: ${props => props.float ? "0.25rem" : ""};
`;

export default Box;
