import React from 'react'
import {navigate} from '@reach/router'

import View from '../components/View'
import Box from '../components/Box'
import Text from '../components/Text'
import Entry from '../components/Entry'
import {range} from 'lodash'
import Tempo from '../components/Tempo'


import Timer from './timer'

//import workouts from '../content/workouts'

const slug = (path) => path.toLowerCase().replace(/ /gi, "-")

class Exercise extends React.Component {
    constructor(props) {
        super(props)
        this.state = {next: [0, 0]}
    }

    render() {
        // eslint-disable-next-line
        let {workout, day, group, exercises} = this.props
        let {next} = this.state
        return <React.Fragment>
            <View {...this.props}>
                <Box
                    weight={1}
                    col
                    abs
                    mw="450px"
                    w="100vw"
                    justify="center"
                    p="0.5rem 1.0rem"
                    m="0 auto">
                    {exercises.map(({movement, sets, reps, tempo}, index) => {
                        return <Box
                            col
                            float
                            m="0.1rem"
                            p="0.5rem"
                            mh="300px">
                            <Text upper big bb="solid 1px tomato" block mw="100%">
                                {movement}
                            </Text>
                            <Box items="stretch" ml="1.5rem" mr="1.5rem">
                                <Box col weight="1" m="0.5rem">
                                    <Text upper>
                                        reps: <Text bold>
                                        {reps}
                                    </Text>
                                </Text>
                            </Box>
                            <Box
                                mt="0.5rem"
                                mb="0.5rem"
                                items="flex-end"
                                weight="1"
                                justify="flex-end">
                                <Entry
                                    type="text"
                                    placeholder="Weight/Bands"
                                    upper
                                    small
                                    size={12}/>
                            </Box>
                        </Box>
                        <Box ml="1.0rem">
                            <Tempo code={tempo}/>
                        </Box>
                        <Box items="center" mb="1rem">
                            <Box m="0.25rem">
                                <Text background>◄</Text>
                            </Box>
                            <Box col weight="1">
                                <Box p="0.25rem" bgbody>
                                    <Text small upper background>
                                        this session
                                    </Text>
                                </Box>
                                <Box>

                                    {range(sets).map((_index) =>
                                        <Box
                                            weight="1"
                                            b="1px solid #222222"
                                            bt="none"
                                            bl={_index ? "none" : ""}
                                            p="0.2rem"
                                            bghighlight={next[index] === _index}
                                            justify="center"
                                            onClick={() => {
                                                if (next[index] === _index)
                                                    navigate(slug(this.props.path + "/" + movement))
                                            }}>
                                            {next[index] === _index ?
                                                <Text background upper >Start</Text>
                                                :
                                                <Text background>Results</Text>
                                            }
                                        </Box>
                                    )}

                                </Box>
                            </Box>
                            <Box m="0.25rem">
                                <Text background>►</Text>
                            </Box>
                        </Box>
                    </Box>
                })}
                {exercises.length === 1 &&
                    <Box weight="1"/>
                }
            </Box>
            <Box h="1rem">
            </Box>
        </View>
        {exercises.map((exercise, index) =>
            <Timer
                key={index} {...exercise}
                path={slug(this.props.path + "/" + exercise.movement)} />
        )}

    </React.Fragment>
}
}

export default Exercise
