import React from 'react';
import {Match} from '@reach/router'
import Screen from './Screen'

function View({path, vert, children, ...rest}) {

    return <Match path={path + "/*"}>
        {function (props) {
            let {match, location} = props
            let subpath = (match && location.pathname !== match.uri)
            if (vert)
                return <Screen hidetop={subpath} {...props} readybottom={!match} {...rest}>
                    {children}
                </Screen>
            else
                return <Screen hideleft={subpath} {...props} readyright={!match} {...rest}>
                    {children}
                </Screen>
        }}
    </Match>
}

export default View
