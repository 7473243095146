import React from 'react'
import {navigate} from '@reach/router'

import View from '../components/View'
import Box from '../components/Box'
import Circle from '../components/Circle'
import Button from '../components/Button'
import Entry from '../components/Entry'

import pattern from '../assets/diagonal-lines.svg'

export default class Login extends React.Component {

    onLogin = (ev) => {
        //TODO finish Login
        navigate("/workout")
    }
    render() {
        return <View {...this.props} path="/login">
            <Circle img={pattern} bg="none" x="100vw" y="100vh"/>
                <Box weight={1} justify="center" items="center" col>
                    <Box m="0.5rem">
                        <Entry type="text" placeholder="email"/>
                    </Box>
                    <Box m="0.5rem" mb="1rem">
                        <Entry type="password" placeholder="password"/>
                    </Box>
                    <Button big solid hvcol="#444444" prhighlight onClick={this.onLogin}>Log In</Button>
                </Box>
        </View>
    }
}
