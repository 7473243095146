import styled from 'styled-components'

const Popout = styled.div`
    box-sizing : border-box;
    z-index: 99;
    position: absolute;
    box-shadow: 0px 2px 5px 0px #0003;
    border-radius: 1rem;
    padding: 1rem;
    background: ${props => props.theme.background};
    left: ${props => props.x ? props.x : "0"};
    top: ${props => props.y ? props.y : "0"};
`

export default Popout
