import React from 'react'
import styled from 'styled-components'
import Text from './Text'
import Box from './Box'
import {sum} from 'lodash'

const DiagramFrame = ({children, width, ...rest}) => <svg width={(width*20)+"px"} height="48px" viewBox={`0 0 ${width*20} 48`} {...rest}>
    {children}
</svg>

const Number = styled.text`
    font-weight: bold;
`

const Line = ({x, length, up, flat}) => {
    let y1 = up ? "24" : "4"
    let y2 = flat ? y1 : up ? "4" : "24"
    return <line x1={(x+1)*20} x2={(x+1+length)*20} y1={y1} y2={y2} stroke="tomato" strokeWidth="2"/>
}

function Tempo({code}) {
    if (!parseInt(code))
        return <Box ml="0.5rem">
            <Text>No Tempo</Text>
        </Box>
    let nums = code.split('').map((num) => parseInt(num));
    let width = sum(nums)
    console.log(width, nums)
    return <DiagramFrame width={width+2}>
        {nums.map((len, index) => <Line key={index} x={sum(nums.slice(0,index))} length={len} flat={index%2} up={index===2}/>)}
        {nums.map((len, index) => len && <Number x={(sum(nums.slice(0, index)) + 1 + len/2)*20} y={40} textAnchor="middle">{len}</Number>)}
    </DiagramFrame>
}

export default Tempo
