import React, { Component } from 'react';

//import logo from './logo.svg';
import Signature from './signature'
import Style from './components/Style'
import Screen from './components/Screen'

import Home from './containers/home'
import Login from './containers/login'
import Workout from './containers/workout'

class App extends Component {

    componentDidMount() {
        let scrollOnLoad = () => {
            document.removeEventListener('load', scrollOnLoad)
            window.scrollTo(0, 100);
        }
        document.addEventListener('load', scrollOnLoad, false)
    }

  render() {
    return <Style>
        <Screen nobutton>
            <Home />
            <Login />
            <Workout />
            <Signature/>
        </Screen>
    </Style>
  }
}

export default App;
