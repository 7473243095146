import React from 'react'
import styled from 'styled-components'
import {navigate} from '@reach/router'

import Button from './Button'

const getTop = (props) => props.readytop ? "-100vh" :
                          props.readybottom ? "100vh" :
                          props.hidetop ? "-100vh" :
                          props.hidebottom ? "100vh" : "0"
const getLeft = (props) => props.readyleft ? "-100vw" :
                           props.readyright ? "100vw" :
                           props.hideleft ? "-100vw" :
                           props.hideright ? "100vw" : "0"

const getOpacity = (props) => props.readytop ? "0" :
                            props.readybottom ? "0" :
                            props.hidetop ? "0" :
                            props.hidebottom ? "0" :
                            props.readyleft ? "0" :
                            props.readyright ? "0" :
                            props.hideleft ? "0" :
                            props.hideright ? "0" : "1"

const Screen = styled.div`
    font-family: 'proxima_nova';
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    top: 0;
    left: 0;
    transform: translate(${props => getLeft(props) + "," + getTop(props)});
    width: 100vw;
    height: ${props => (window.innerHeight+1) + "px"};
    padding: 1rem 0.5rem;
    box-sizing: border-box;
    overflow: hidden;
    transition: ${props => props.tr ? props.tr : "0.5s ease"};
    opacity: ${props => getOpacity(props)};
`

export default function ({children, nobutton, ...rest}) {
    console.log(rest.location)
    return <Screen {...rest}>
        {!nobutton && window.navigator.standalone && <Button onClick={() => window.history.back()} small left>back</Button>}
        {children}
    </Screen>
}
