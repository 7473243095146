import React from 'react'
import {ThemeProvider} from 'styled-components'

const styles = {
    primary: "#888"/*"#4891fc"*/,
    secondary: "#3b45a8",
    highlight: "tomato",
    alternative: "#274060",
    body: "#222222",
    bodylight: "#444444",
    contrast: "tomato",
    altdark: "#1b2845",
    cardgrey: "#f0f0f0",
    background: "#ffffff",
}

export default ({children}) => <ThemeProvider theme={styles}>
    {children}
</ThemeProvider>
