import styled from 'styled-components'

const getColor = (props) => props.col ? props.col :
props.primary ? props.theme.primary :
props.secondary ? props.theme.secondary :
props.background ? props.theme.background :
props.highlight ? props.theme.highlight :
props.alternative ? props.theme.alternative :
props.theme.body;

const getBackground = (props) => props.bg ? props.bg :
props.bgprimary ? props.theme.primary :
props.bgsecondary ? props.theme.secondary :
props.bgbackground ? props.theme.background :
props.bghighlight ? props.theme.highlight :
props.bgalternative ? props.theme.alternative :
props.theme.background;

const getHover = (props) => props.hvcol ? props.hvcol :
props.hvprimary ? props.theme.primary :
props.hvsecondary ? props.theme.secondary :
props.hvbackground ? props.theme.background :
props.hvhighlight ? props.theme.highlight :
props.hvalternative ? props.theme.alternative :
getColor(props);

const getPress = (props) => props.prcol ? props.prcol :
props.prprimary ? props.theme.primary :
props.prsecondary ? props.theme.secondary :
props.prbackground ? props.theme.background :
props.prhighlight ? props.theme.highlight :
props.pralternative ? props.theme.alternative :
getHover(props);

const Button = styled.button`
padding: ${props => props.small ? "0.25rem 0.5rem" :
props.big ? "1.0rem 2.0rem" :
"0.5rem 1.0rem"};
margin: 0rem 0.5rem;
font-weight: ${props => props.text ? "bold" : ""};
border: ${props => props.solid || props.text ? "none" : "1px solid " + getColor(props)};
border-radius: ${props => props.small ? "0.25rem" :
props.big ? "1.0rem" :
"0.5rem" };
text-transform: uppercase;
color: ${props => props.solid ? getBackground(props) : getColor(props)};

align-self: ${props => props.right? "flex-end" : props.left ? "flex-start" : props.center ? "center" : ""};
font-size: ${props => props.small? "0.8rem" : "1rem"};
background: ${props => props.solid ? getColor(props) : getBackground(props)};

&:hover {
    color: ${props => props.solid ? getBackground(props) : getHover(props)};
    border: ${props => props.solid || props.text ? "none" : "1px solid " + getHover(props)};
    background: ${props => props.solid ? getHover(props) : getBackground(props)};
}
&:active {
    color: ${props => props.solid ? getBackground(props) : getPress(props)};
    border: ${props => props.solid || props.text ? "none" : "1px solid " + getPress(props)};
    background: ${props => props.solid ? getPress(props) : getBackground(props)};
}
box-shadow: ${props => props.solid ? "0px 2px 5px 0px #0003" : ""};
`

export default Button
