import React from 'react';
import styled, {keyframes, createGlobalStyle} from 'styled-components';

const color = "tomato";

const GlobalStyle = createGlobalStyle`
@font-face {
font-family: 'proxima_nova';
src: url('https://s3-ap-southeast-2.amazonaws.com/think-static-resources/fonts/ProximaNovaBold.eot');
src: url('https://s3-ap-southeast-2.amazonaws.com/think-static-resources/fonts/ProximaNovaBold.eot?#iefix') format('embedded-opentype'),
     url('https://s3-ap-southeast-2.amazonaws.com/think-static-resources/fonts/ProximaNovaBold.woff2') format('woff2'),
     url('https://s3-ap-southeast-2.amazonaws.com/think-static-resources/fonts/ProximaNovaBold.woff') format('woff'),
     url('https://s3-ap-southeast-2.amazonaws.com/think-static-resources/fonts/ProximaNovaBold.ttf') format('truetype'),
     url('https://s3-ap-southeast-2.amazonaws.com/think-static-resources/fonts/ProximaNovaBold.svg#proxima_nova_altbold') format('svg');
font-weight: 700;
font-style: normal;

}

@font-face {
    font-family: 'proxima_nova';
    src: url('https://s3-ap-southeast-2.amazonaws.com/think-static-resources/fonts/ProximaNovaRegular.eot');
    src: url('https://s3-ap-southeast-2.amazonaws.com/think-static-resources/fonts/ProximaNovaRegular.eot?#iefix') format('embedded-opentype'),
         url('https://s3-ap-southeast-2.amazonaws.com/think-static-resources/fonts/ProximaNovaRegular.woff2') format('woff2'),
         url('https://s3-ap-southeast-2.amazonaws.com/think-static-resources/fonts/ProximaNovaRegular.woff') format('woff'),
         url('https://s3-ap-southeast-2.amazonaws.com/think-static-resources/fonts/ProximaNovaRegular.ttf') format('truetype'),
         url('https://s3-ap-southeast-2.amazonaws.com/think-static-resources/fonts/ProximaNovaRegular.svg#proxima_nova_altregular') format('svg');
    font-weight: 400;
    font-style: normal;

}
`;

const animation = keyframes`
  0% {
    transform: translateY(-10px);
    opacity: 0;
    -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
    clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
  }
  12% {
    transform: translateY(0);
    opacity: 1;
    -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
    clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
  }
  48% {
    transform: translateY(0);
    opacity: 1;
    -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
    clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
  }
  60% {
    transform: translateY(10px);
    opacity: 0;
    -webkit-clip-path: polygon(100% 0, 100% 0%, 0 100%, 0 100%);
    clip-path: polygon(100% 0, 100% 0%, 0 100%, 0 100%);
  }
`

const Wrapper = styled.div`
    position: absolute;
    top: ${props => (window.innerHeight -30) + "px"}
    left: 0;
    width: 100vw;
    display: flex;
    justify-content: center;
    z-index: 99;
`

const SignatureContainer = styled.a`
  font-family: 'proxima_nova', sans-serif;
  width: 160px;
  height: 20px;
  margin: 0 auto;
`;

const Line = styled.h1`
    font-weight: 400;
    font-size: 14px;
    text-transform: lowercase;
    position: relative;
    right: -107px;
`;


const Word = styled.span`
    position: absolute;
    width: 100%;
    color: #fff;
    -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
    clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
    transform: translateY(-50px);
    opacity: 0;
    animation: ${animation} 6.0s ease infinite forwards;
    right: ${props => props.right ? props.right : 'initial'};
    left: ${props => props.left ? props.left : 'initial'};
    animation-delay: ${props => props.delay ? props.delay: 'initial'};
    color: ${props => props.blue ? color : "initial"};
    font-weight: ${props => props.blue ? "700" : "400"};
    top: ${props => props.blue ? "-1px" : "0px"};
`;

export default () =>
<Wrapper>
    <SignatureContainer href="https://teamthink.co">
        <GlobalStyle/>
        <Line>
            <Word right="104px" delay="0.7s">another</Word>
            <Word right="53px" delay="0.6s">creative</Word>
            <Word right="0px" delay="0.5s" blue>journey</Word>
        </Line>
        <Line>
            <Word left="-37px" delay="3.6s">made</Word>
            <Word delay="3.5s">by</Word>
            <Word left="18px" delay="3.4s" blue>think</Word>
        </Line>
    </SignatureContainer>
</Wrapper>
